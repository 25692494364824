import { useAuthStore } from '@/stores/userStore';  
import * as apiService from '@/services/apiService.js';

export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
    };
  },
  mounted() {
    const authStore = useAuthStore();  
    if (authStore.isAuthenticated) { 
      this.$router.push('/admin');
    }
  },
  methods: {
    async handleLogin() {
      try {  
        apiService.login(this.username, this.password, this.setLoading)
          .then(response => {  
            if (response.checked == 1) {  
              const userStore = useAuthStore();
              userStore.setlogin(response.token, this.username); 
              this.$router.push('/admin'); 
            } else { 
              alert("Invalid username or password");
            }
          })
          .catch(error => {
            console.error("Login failed:", error); 
          });
      } catch (error) {
        console.error("Login failed:", error); 
      }
    },
    setLoading(loading) {
      this.isLoading = loading;
    }
  }
};