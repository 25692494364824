import { useAuthStore } from '@/stores/userStore';  
import * as apiService from '@/services/apiService.js'; // 使用 * 导入所有导出


export default {
    name: "AppLayout",
    data() {
        return {
            treeGroups: [], 
            rssNodes: [],   
            itm: null,
            isLoading: true,
            isSidebarHidden: false,
            selectedNode: null,
            isLoggedIn: false,
            selectedArticles: [],
            totalArticles: 0,  // 总文章数
            currentPage: 1,    // 当前页码
            articlesPerPage: 100, // 每页文章数
            isResizingVertical: false, 
            sidebarWidth: 250,
            isResizing: false,
            startX: 0,
            articleListHeight: 200,
            articleSummaryHeight: 200,
            isHorizontalResizing: false,
            startY: 0,
          }; 
    },
    computed: {
      totalPages() {
        // 根据总文章数和每页文章数计算总页数
        return Math.ceil(this.totalArticles / this.articlesPerPage);
      }
    },
    methods: { 
      async playArticle(article) { 
        const token = localStorage.getItem("userToken") 
        if (!token) { 
          this.$router.push('/login');
          return;
        }
        try {
          const response = await apiService.playArticle(article, this.setLoading,token);
          const audioFilePath = response.url; 
          this.$refs.audioPlayer.src = audioFilePath;  // 设置音频源
          this.$refs.audioPlayer.play();  // 播放音频
        } catch (error) {
          console.error("播放音频时发生错误：", error);
        } 
      },
      redirectToSetup() {
        const authStore = useAuthStore();
        this.isLoggedIn= authStore.isAuthenticated;
        if (this.isLoggedIn) {
          this.$router.push('/admin');
        } else {
          this.$router.push('/login');
        }
      },
      setLoading(value) {
        this.isLoading = value;
      },
      async fetchData() {
        try {
          this.treeGroups = await apiService.fetchFeedGroups(this.setLoading);
         this.rssNodes = await apiService.fetchRssNodes(this.setLoading);
         
              if (this.rssNodes  && this.rssNodes.length > 0) {
                const firstNode = this.rssNodes.find(node => node.isFirst === 1); 
                if (firstNode) {
                    this.selectFeedNode(firstNode); 
                }
              }
        } catch (error) {
          console.error("Error fetching data:", error);
        } 
    },
    async articleOpen(articleId, articleLink) {
      try {  
        await apiService.articleOpen(articleId,this.setLoading);  
      } catch (error) 
      { 
        console.error('Error recording article open:', error);
      }
      finally
      { 
        console.error("articleLink :", articleLink);
      }
    },
    async selectFeedNode(node, resetPage = true) {
        try {
          if (resetPage) {
            this.currentPage = 1;
          }
          this.selectedNode = node;
          const response = await apiService.fetchFeedsBySite(
            node.id, 
            this.currentPage, 
            this.articlesPerPage, 
            this.setLoading
          );
          this.selectedArticles = response.items;
          this.totalArticles = response.totalItems;  // 更新总文章数
          this.currentPage = response.pageIndex;     // 更新当前页 
          
          this.selectedNode.totalFeed =response.totalItems;
        } catch (error) {
          console.error("Error fetching articles:", error);
        }
      }, 
      changePage(page) {
        // 切换页码并重新获取文章数据
        if (page >= 1 && page <= this.totalPages) {
          this.currentPage = page;
          this.selectFeedNode(this.selectedNode, false);  // 重新获取当前页的数据
        }
      },
      toggleSidebar() {
        this.isSidebarHidden = !this.isSidebarHidden;
      },
      
      toggleSidebarFromDivider() {
        // Only allow toggle if the sidebar is hidden
        if (this.isSidebarHidden) {
          this.isSidebarHidden = false; 
        }
      },
      toggleSelectArticle(article)
      {
        article.isSelected = !article.isSelected;
      }, 
        
          getNodesForGroup(groupId) {  
            const filteredNodes = this.rssNodes.filter(node => node.groupId === groupId);
            return filteredNodes;
          },
          toggleGroup(group) {
            group.isOpen = !group.isOpen; // 切换展开状态 
        },
        async selectArticle(article) {
            this.itm = article; // 选中节点  
          }, 
      // Start vertical resizing (for sidebar width)
      startResizingVertical(event) {
        this.isVerticalResizing = true;
        this.startX = event.clientX;
        document.addEventListener("mousemove", this.resizeSidebar);
        document.addEventListener("mouseup", this.stopResizingVertical);
      },
      resizeSidebar(event) {
        if (this.isVerticalResizing) {
          const delta = event.clientX - this.startX;
      
          // 限制 sidebarWidth 的变化范围
          let newSidebarWidth = this.sidebarWidth + delta;
          
          if (newSidebarWidth < 150) {
            newSidebarWidth = 150; // 最小宽度限制
          }
          if (newSidebarWidth > 500) {
            newSidebarWidth = 500; // 最大宽度限制
          }
      
          this.sidebarWidth = newSidebarWidth; 
          this.startX = event.clientX;
          console.log("Sidebar width: ", this.sidebarWidth);  // Log the width
        }
      },
      stopResizingVertical() {
        this.isVerticalResizing = false;
        document.removeEventListener("mousemove", this.resizeSidebar);
        document.removeEventListener("mouseup", this.stopResizingVertical);
      },
  
      // Start horizontal resizing (for article list height)
      startResizingHorizontal(event) {
        this.isHorizontalResizing = true;
        this.startY = event.clientY;
        document.addEventListener("mousemove", this.resizeHorizontal);
        document.addEventListener("mouseup", this.stopResizingHorizontal);
      },
      resizeHorizontal(event) {
        if (this.isHorizontalResizing) {
          const delta = event.clientY - this.startY; // 获取垂直方向的拖动距离cd
          const contentAreaHeight = this.$refs.contentArea.offsetHeight; // 获取 content-area 的总高度
      
          let newArticleListHeight = this.articleListHeight + delta; // 计算新的 article-list 高度
          let newArticleSummaryHeight = contentAreaHeight - newArticleListHeight; // 计算新的 article-summary 高度 
          // 限制最小高度
          if (newArticleListHeight < 100) {
            newArticleListHeight = 100;
            newArticleSummaryHeight = contentAreaHeight - newArticleListHeight;
          }
      
          if (newArticleSummaryHeight < 100) {
            newArticleSummaryHeight = 100;
            newArticleListHeight = contentAreaHeight - newArticleSummaryHeight;
          }
      
          // 应用新的高度
          this.articleListHeight = newArticleListHeight;
          this.articleSummaryHeight = newArticleSummaryHeight;
      
          this.startY = event.clientY; // 更新开始位置
        }
      } 
      ,
      stopResizingHorizontal() {
        this.isHorizontalResizing = false;
        document.removeEventListener("mousemove", this.resizeHorizontal);
        document.removeEventListener("mouseup", this.stopResizingHorizontal);
      }
    },
    mounted() { 
      this.fetchData();
    }
  };
  