import { useAuthStore } from '@/stores/userStore';
import * as apiService from '@/services/apiService.js';
import AddRssNode from './AddRssNode.vue';

export default {
  name: "AdminDashboard",
  components: {
    AddRssNode
  },
  data() {
    return {
      userName: "Rss user",
      rssNodes: [],  // rssNodes 初始为空数组
      currentEntity: {},
      isModalOpen: false,
      isEditing: false,
      selectedRssNode: null,
      selArticles: [],
      statistics: [
        { metric: 'Total Users', value: 150 },
        { metric: 'Active Sessions', value: 42 },
        { metric: 'New Signups', value: 10 },
      ],
      isLoading: false,
    };
  },
  mounted() {
    try {
      const userToken = localStorage.getItem('userToken');
      this.setLoading(true); // 启动加载状态
      apiService.LConn(userToken, this.setLoading)
        .then(response => {
          if (response.checked === 1) {
            console.log('LConn:', response);
            return apiService.AdminNodes(this.setLoading,userToken); // 返回 Promise
          } else {
            console.log('No valid token or username found, logging out.');
            this.logexit();
          }
        })
        .then(rssNodesResponse => {
          if (rssNodesResponse) {
            this.rssNodes = rssNodesResponse; // 赋值 rssNodes
            console.log('Fetched RssNodes:', this.rssNodes);
          }
        })
        .catch(error => {
          console.error('Error during API call:', error);
          this.logexit();
        })
        .finally(() => {
          this.setLoading(false); // 关闭加载状态
        });
        apiService.GetSeletedArticle(this.setLoading,userToken).then(response=>{
            this.selArticles= response;
        }); 
    } catch (error) {
      this.logexit();
    }
  },
  methods: {
    openAddModal() {
        this.isModalOpen = true;
        this.isEditing = false; 
        this.currentEntity = {}; 
    }, 
    editRssNode(node) {
        this.isEditing = true;
        this.currentEntity = { ...node }; // Clone the node for editing
        this.isModalOpen =true;
      },
      addRssNode(newNode) {
        this.rssNodes.push(newNode); // Add new node
        debugger;
        this.saveNode(newNode);
      },
      updateRssNode(updatedNode) {
        debugger;
        const index = this.rssNodes.findIndex(node => node.id === updatedNode.id);
        if (index !== -1) {
          this.rssNodes.splice(index, 1, updatedNode);
          this.saveNode(updatedNode);
        }
      },
    closeModal() {
      this.isModalOpen = false;  
      this.isEditing = false;    
      this.currentEntity = {}; 
     }, 
    submitForm() {
      if (this.isEditing) {
        // 编辑模式
        debugger;
        const index = this.rssNodes.findIndex(node => node.id === this.form.id);
        if (index !== -1) {
          this.$set(this.rssNodes, index, { ...this.form });
        }
      } else {
        // 新增模式
        const newNode = { ...this.form, id: Date.now() }; // 使用当前时间戳生成 ID
        this.rssNodes.push(newNode);
      }
      this.closeModal();
    },
    async deleteEntry(id) {
      this.rssNodes = this.rssNodes.filter(node => node.id !== id);
      try { 
        const token = localStorage.getItem("userToken") 
        await apiService.DelNode(id, this.setLoading,token);
        console.log('Node deleted successfully!');
        // 你可以在这里刷新列表或者关闭弹窗等操作
      } catch (error) {
        console.error('Failed to deleted node:', error);
      }
    }, 
    async saveNode(node) {
        try {
          const token = localStorage.getItem("userToken")
          await apiService.addOrUpdateNode(node, this.setLoading,token);
          console.log('Node saved successfully!');
          // 你可以在这里刷新列表或者关闭弹窗等操作
        } catch (error) {
          console.error('Failed to save node:', error);
        }
      },
    logexit() {
      const userStore = useAuthStore();
      userStore.setlogout();
      this.$router.push('/login'); // 重定向到登录页
    },
    setLoading(loading) {
      this.isLoading = loading;
    }
  },
};
