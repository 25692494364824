<template>
  <div class="login-container">
    <h1 class="text-center">Login</h1>
    <form @submit.prevent ="handleLogin">
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" v-model="username" class="form-control" id="username" required />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" v-model="password" class="form-control" id="password"  autocomplete="on" required />
      </div>
      <button type="submit" class="btn btn-primary btn-block">Login</button>
    </form>
  </div>
</template>  
<script src="./LoginPage.js"></script> 
<style scoped>
.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f9fa; /* Light background color for better visibility */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

h1 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}
</style>