import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; 
import { createPinia } from 'pinia'; // Import Pinia
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/main.css'; // 引入全局样式
  
const app = createApp(App);
app.use(router);  // Use the router
app.use(createPinia())
app.mount('#app');