<template>
  <div class="app-layout">
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>  
    </div>
    <div class="main-content">
      <!-- Sidebar (left) -->
      <div class="resizable-sidebar" :style="{ width: sidebarWidth + 'px' }" :class="{ 'hidden': isSidebarHidden }"> 
          <div class="toggle-sidebar-btn" @click="toggleSidebar">
            <h6 class="p-2">订阅</h6>
            <span v-if="isSidebarHidden">▶</span>
            <span v-else>◀</span>
          </div>

        
        <ul class="list-group">
          <li v-for="group in treeGroups" :key="group.id" class="list-group-item">
            <strong @click="toggleGroup(group)" class="group-header">
              <span>{{ group.isOpen ? '[-]' : '[+]' }}</span> 
              {{ group.name }}
            </strong>

            <ul v-show="group.isOpen">
              <li v-for="node in getNodesForGroup(group.id)" :key="node.id" class="tree-node" 
              :class="{ active: selectedNode === node }"
              @click="selectFeedNode(node)" >
                <img :src="'data:image/png;base64,' + node.siteIcon" alt="icon" />
                {{ node.siteName }} 
                <span class="node-count">{{ node.totalFeed }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- Resizable divider (vertical) -->
      <div class="divider-vertical" @mousedown="startResizingVertical"></div>

      <div class="content-area" ref="contentArea">
        <!-- Article List (顶部部分) -->
        <div class="article-list p-3" :style="{ height: articleListHeight + 'px' }">
          <div class="article-header">
            <h6>  <!-- Keep h6 inline -->
              <a href="javascript:void(0)" @click="toggleSidebarFromDivider">
                {{ selectedNode ? selectedNode.siteName : '' }}
              </a>
              - 文章列表
            </h6>
            <a class="setuplink" @click="redirectToSetup">偏好设置</a>
          </div>
          <ul class="list-group">
            <li
              v-for="article in selectedArticles"
              :key="article.id"
              class="list-article-item"
              @click="selectArticle(article)"
            > 
              <input type="checkbox" class="checkbox" :checked="article.isSelected" @change="toggleSelectArticle(article)" />
              <img src="@/assets/images/star.svg" class="icon bookmark-icon" alt="bookmark icon" /> 
              <img src="@/assets/images/rss.svg" class="icon rss-icon" alt="rss icon" />  
              <span class="article-title">{{ article.title }}</span> 
              <span class="pub-date">{{ article.displayPubDate }}</span>  
            </li>
          </ul>
           <!-- Pagination Controls -->
          <div class="pagination-controls">
            <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">上一页</button>
            <span>第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
            <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">下一页</button>
          </div>

        </div>

        <!-- Divider for resizing -->
        <div class="divider-horizontal" @mousedown="startResizingHorizontal"></div>

        <!-- Article Summary (底部部分) -->
        <div class="article-summary p-3" :style="{ height: articleSummaryHeight + 'px', overflowY: 'auto' }">
          <a 
            v-if="itm" 
            :href="itm.link || '#'" 
            target="_blank"
            @click ="articleOpen(itm.id, itm.link)" 
          >
            {{ itm?.title || "" }}           
          </a>
           <!-- 播放按钮 -->
           <button v-if="itm?.title" class="play-button" @click.stop="playArticle(itm)">
            ▶ 语音播报
          </button> 
          <div v-html="itm?.excerpt || ' '"></div>
          <!-- Audio player -->
          <audio ref="audioPlayer" controls style="display:none;"></audio>
        </div>
      </div>
    </div>
  </div>
</template>

    <script src="./AppLayout.js"></script>
    <style src="@/assets/styles/components.css" scoped></style>
    <style src="@/assets/styles/AppLayout.css" scoped></style>
    