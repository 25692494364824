<template>
    <div class="admin-dashboard">
      <!-- Welcome Section -->
      <div class="welcome-section">
        <h6>Welcome, {{ userName }}!</h6>
         <!-- OnlineTTS Link -->
       <a :href="'/tts'" style="float: left; margin-right: 10px;">Online TTS</a>
       <a :href="'/'" style="margin-right: 10px;">返回主页</a>
        <button style="float: right;" @click="logexit">Logout</button>
      </div>
  
      <!-- Data Table Section (CRUD) -->
      <div class="data-table-section">
        <h2>Data Table Management</h2>
        <button @click="openAddModal">Add New Entry</button>
          <!-- Data Table -->
            <table class="table table-bordered">
            <thead>
                <tr>
                <th>ID</th>
                <th>Group ID</th>
                <th>Site URL</th>
                <th>Site Name</th>
                <th>API Host</th>
                 <!--<th>Site Icon</th>-->
                <th>Last Update</th>
                <th>Is First</th>
                <th>Total Feed</th>
                <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(node, index) in rssNodes" :key="index">
                <td>{{ node.id }}</td>
                <td>{{ node.groupId }}</td>
                <td>{{ node.siteUrl }}</td>
                <td>{{ node.siteName }}</td>
                <td>{{ node.apiHost }}</td>
                <!--<td>{{ node.siteIcon }}</td>-->
                <td>{{ node.lastUpdate }}</td>
                <td>{{ node.isFirst }}</td>
                <td>{{ node.totalFeed }}</td>
                <td>
                    <button @click="editRssNode(node)">Edit</button>
                    <button @click="deleteEntry(node.id)">Delete</button>
                </td>
                </tr>
            </tbody>
            </table>
 
            <!-- Add Modal -->
        <AddRssNode 
        v-if="isModalOpen" 
        :isEditing="isEditing"
        :entity="currentEntity"
        :isVisible="isModalOpen"  
        @addRssNode="addRssNode"
        @updateRssNode="updateRssNode"
        @closeModal="closeModal"/>

 

      </div>
  
      <!-- Statistics Section -->
      <div class="statistics-section">
        <h2>Statistics & Analysis</h2>

        <table class="table table-bordered">
            <thead>
                <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Link</th>
                <th>PubDate</th>
                <th>RssNode Id</th> 
                <th>Is Selected</th>
                <th>Is Readed</th> 
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in selArticles" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.link }}</td>
                <td>{{ item.rssNodeId }}</td>
                <td>{{ item.isSelected }}</td> 
                <td>{{ item.isReaded }}</td> 
                </tr>
            </tbody>
            </table>
      </div>
    </div>
  
</template>
  
  
<script src="./AdminDashboard.js"></script>
  
<style scoped>

.admin-dashboard {
    padding: 20px;
  }
  
.welcome-section {
    margin-bottom: 20px;
  }
  
.statistics-section {
    margin-bottom: 30px;
  }
  
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 400px; /* 确保足够的宽度 */
  max-height: 90vh; /* 确保足够的高度 */
  overflow-y: auto; /* 表单过长时自动滚动 */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

table {
    width: 100%;
    border-collapse: collapse;
  }
  
th, td {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
button {
    margin-right: 10px;
  }

</style>
  