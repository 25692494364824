<template>
    <div class="modal" ref="modalElement">
      <h3>{{ isEditing ? 'Edit RssNode' : 'Add New RssNode' }}</h3>
      <form @submit.prevent="submitForm">
        <div v-for="(field, index) in formFields" :key="index" class="form-group">
          <label :for="field.name">{{ field.label }}</label>
          <input
            v-model="form[field.name]"
            :type="field.type"
            :id="field.name"
            :placeholder="field.placeholder"
            required
          />
        </div>
        <button type="submit">{{ isEditing ? 'Update' : 'Submit' }}</button>
        <button type="button" @click="closeModal">Cancel</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isEditing: {
        type: Boolean,
        default: false
      },
      entity: {
        type: Object,
        default: () => ({})
      },
      isVisible: {
        type: Boolean,
        default: false // Control visibility from the parent
      }
    },
    data() {
      return {
        formFields: [
          { name: 'groupId', label: 'Group ID', type: 'text', placeholder: 'Enter Group ID' },
          { name: 'siteUrl', label: 'Site URL', type: 'text', placeholder: 'Enter Site URL' },
          { name: 'siteName', label: 'Site Name', type: 'text', placeholder: 'Enter Site Name' },
          { name: 'apiHost', label: 'API Host', type: 'text', placeholder: 'Enter API Host' },
          { name: 'siteIcon', label: 'Site Icon', type: 'text', placeholder: 'Enter Site Icon URL' },
          { name: 'lastUpdate', label: 'Last Update', type: 'date', placeholder: '' },
          { name: 'isFirst', label: 'Is First', type: 'number', placeholder: 'Enter 0 or 1' },
          { name: 'isEnable', label: 'Is Eanble', type: 'number', placeholder: 'Enter 0 or 1' }
        ],
        form: {
          groupId: '',
          siteUrl: '',
          siteName: '',
          apiHost: '',
          siteIcon: '',
          lastUpdate: '',
          isFirst: 0,
          isEnable: 0
        }
      };
    },
    mounted() {
      this.showModal(); // Call showModal on mount
    },
    watch: {
      // Watch for changes in the entity prop to update the form
      entity: {
        handler(newEntity) {
          if (this.isEditing) {
            this.form = { ...newEntity }; // Populate the form with the entity data for editing
          }
        },
        immediate: true // Trigger the watcher immediately
      },
      isVisible: {
        handler(newVal) {
          if (!newVal) {
            this.closeModal(); // Call closeModal if isVisible changes to false
          } else {
            this.showModal(); // Call showModal if isVisible changes to true
          }
        },
        immediate: true // Trigger the watcher immediately
      }
    },
    methods: {
      showModal() {
        const modalElement = this.$refs.modalElement;
        if (modalElement && window.getComputedStyle(modalElement).display === 'none') {
          modalElement.style.display = 'block'; // Show the modal
        }
      },
      closeModal() {
        const modalElement = this.$refs.modalElement;
        if (modalElement) {
          modalElement.style.display = 'none'; // Hide the modal
        }
        this.$emit('closeModal'); // Emit close modal event
      },
      submitForm() {
        console.log('Form Submitted:', this.form);
        this.$emit(this.isEditing ? 'updateRssNode' : 'addRssNode', { ...this.form });
        this.closeModal(); // Close modal after submission
      }
    }
  };
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Ensure it is on top */
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
  }
  .form-group {
    margin-bottom: 15px;
  }
  input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  </style>
  