import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/components/AppLayout.vue';
import AdminDashboard from '@/components/AdminDashboard.vue';
import LoginPage from '@/components/LoginPage.vue'; // 确保匹配更新的组件名称
import { useAuthStore } from '@/stores/userStore';
import OnlineTTS from '@/components/OnlineTTS.vue';

const routes = [
  {
    path: '/',
    component: AppLayout,  
  },
  {
    path: '/login',
    component: LoginPage,   
  },
  {
    path: '/admin',
    component: AdminDashboard,   
    meta: { requiresAuth: true },   
  },
  {
    path: '/tts',
    component: OnlineTTS,   
    meta: { requiresAuth: true },   
  }

];

 

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 导航守卫以检查登录状态
router.beforeEach((to, from, next) => {
    try {
    const authStore = useAuthStore();  
    if (to.meta.requiresAuth && !authStore.isAuthenticated) { 
      next('/login'); 
    }
     else
     { 
      next();
        
    }
} catch (error) {
    console.error("Error fetching data:", error);
  } 
});

export default router;
