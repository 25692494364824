<template>
    <div>
      <h1>语音合成</h1>
      <form @submit.prevent="generateAudio">
        <label for="text">文本内容:</label>
        <input type="text" v-model="text" required />
  
        <label for="voice">语音名称:</label>
        <select v-model="voice">
          <option v-for="v in voices" :key="v" :value="v">{{ v }}</option>
        </select>
  
        <div class="slider-container">
          <label for="rate">音量: <span>{{ rate }}</span></label>
          <input type="range" id="rate" v-model="rate" min="-100" max="100" />
        </div>
  
        <div class="slider-container">
          <label for="pitch">语调: <span>{{ pitch }}</span></label>
          <input type="range" id="pitch" v-model="pitch" min="-100" max="100" />
        </div>
  
        <button type="submit">生成语音</button>
      </form>
  
      <div id="audioPlayer">
        <audio controls ref="audioElement">
          <source :src="audioSource" type="audio/mpeg" />
          您的浏览器不支持 audio 元素。
        </audio>
      </div>
  
      <div id="history">
        <h2>历史记录</h2>
        <button @click="clearHistory">清除历史</button>
        <div>
          <div class="history-item" v-for="(item, index) in history" :key="index">
            <span>{{ item.text }}</span>
            <div>
              <button @click="playAudio(item.url)">播放</button>
              <button @click="downloadAudio(item.url)">下载</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useAuthStore } from '@/stores/userStore';
  import * as apiService from '@/services/apiService.js';

  export default {
    data() {
      return {
        text: '',
        voice: 'zh-CN-XiaoxiaoNeural',
        rate: 50,
        pitch: 50,
        audioSource: '',
        voices: [
          'zh-CN-XiaoxiaoMultilingualNeural', 'zh-CN-XiaoxiaoNeural', 'zh-CN-YunxiNeural',
          'zh-CN-YunjianNeural', 'zh-CN-XiaoyiNeural', 'zh-CN-YunyangNeural',
          'zh-CN-XiaochenNeural', 'zh-CN-XiaohanNeural', 'zh-CN-XiaomengNeural',
          'zh-CN-XiaomoNeural', 'zh-CN-XiaoqiuNeural', 'zh-CN-XiaorouNeural',
          'zh-CN-XiaoruiNeural', 'zh-CN-XiaoshuangNeural', 'zh-CN-XiaoyanNeural',
          'zh-CN-XiaoyouNeural', 'zh-CN-XiaozhenNeural', 'zh-CN-YunfengNeural',
          'zh-CN-YunhaoNeural', 'zh-CN-YunjieNeural', 'zh-CN-YunxiaNeural',
          'zh-CN-YunyeNeural', 'zh-CN-YunzeNeural', 'zh-CN-YunfanMultilingualNeural',
          'zh-CN-YunxiaoMultilingualNeural', 'zh-CN-guangxi-YunqiNeural', 'zh-CN-henan-YundengNeural',
          'zh-CN-liaoning-XiaobeiNeural', 'zh-CN-liaoning-YunbiaoNeural', 'zh-CN-shaanxi-XiaoniNeural',
          'zh-CN-shandong-YunxiangNeural', 'zh-CN-sichuan-YunxiNeural', 'zh-HK-HiuMaanNeural',
          'zh-HK-WanLungNeural', 'zh-HK-HiuGaaiNeural', 'zh-TW-HsiaoChenNeural', 'zh-TW-YunJheNeural',
          'zh-TW-HsiaoYuNeural'
        ], 
        isLoading: false,
        history: []
      };
    },
    mounted() {
    const authStore = useAuthStore();  
    if (!authStore.isAuthenticated) { 
      this.$router.push('/login');
    }
   } ,
    methods: {
        async generateAudio() { 
        const token = localStorage.getItem("userToken") 
        if (!token) { 
          this.$router.push('/login');
          return;
        }
        try {
         const tts={
            text : this.text,
            voice: this.voice,
            rate: this.rate,
            pitch :this.pitch,
            audioParam :'audio-24khz-48kbitrate-mono-mp3'

          } 
          const response = await apiService.tts(tts, this.setLoading,token);
          this.audioSource  = response.url;  
          const audioElement = this.$refs.audioElement;
          audioElement.load();
          audioElement.play(); 
          
        this.addHistoryItem(this.text, response.url);
        } catch (error) {
          console.error("播放音频时发生错误：", error);
          this.generateAudio2()
        } 
      },
      generateAudio2() {
        debugger;
        const url = `https://t.leftsite.cn/tts?t=${encodeURIComponent(this.text)}&v=${this.voice}&r=${this.rate}&p=${this.pitch}&o=audio-24khz-48kbitrate-mono-mp3`;
        this.audioSource = url;
        const audioElement = this.$refs.audioElement;
        audioElement.load();
        audioElement.play();
  
        this.addHistoryItem(this.text, url);
      },
      addHistoryItem(text, url) {
        this.history.push({ text, url });
      },
      playAudio(url) {
        this.audioSource = url;
        const audioElement = this.$refs.audioElement;
        audioElement.load();
        audioElement.play();
      },setLoading(loading) {
      this.isLoading = loading;
      },
      downloadAudio(url) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'audio.mp3';
        link.click();
      },
      clearHistory() {
        this.history = [];
      }
    }
  };
  </script>
  
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  label {
    display: block;
    margin-top: 10px;
  }
  input[type="text"], select, button {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
  }
  .slider-container {
    margin-top: 10px;
  }
  .slider-container label {
    display: flex;
    justify-content: space-between;
  }
  #audioPlayer {
    margin-top: 20px;
  }
  #history {
    margin-top: 20px;
  }
  .history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .history-item button {
    margin-left: 10px;
  }
  </style>
  