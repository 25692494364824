import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({ 
    token: localStorage.getItem('userToken') || null,
    userName: localStorage.getItem('userName') || null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  actions: {
    setlogin(token, username) { 
      this.token = token;
      this.userName = username;
      localStorage.setItem('userToken', token);
      localStorage.setItem('userName', username);
    },
    setlogout() {
      this.token = null;
      this.userName = null;
      localStorage.removeItem('userToken');
      localStorage.removeItem('userName');
    }
  }
});