// src/services/apiService.js
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const apiRequest = (url, method = 'get', data = {}, setLoading, timeout = 5000, token = null) => {
  setLoading(true);

  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out'));
    }, timeout);
  });

  const headers = token ? { Authorization: `Bearer ${token}` } : {}; // Add token if provided
  
  if (typeof data !== 'object' || Array.isArray(data)) {
    data = { value: data };
  }
  const requestPromise = axios({
    url: `${API_BASE_URL}${url}`,
    method: method,
    data: data,
    headers: headers,  // Attach headers with JWT token if available
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(`Error in API request: ${error}`);
      throw error;
    });

  return Promise.race([requestPromise, timeoutPromise])
    .finally(() => {
      setLoading(false);
    });
}; 
export const tts = (tts,setLoading,userToken) => {  
  return apiRequest('/MSTTS/tts',  'post', tts,setLoading,50000,userToken);
};
export const playArticle = (article, setLoading,userToken) => {
  return apiRequest(`/rss/playArticle`, 'post', article, setLoading,50000,userToken);
};
export const AdminNodes = (setLoading,userToken) => {  
  return apiRequest('/rss/AdminNodes',  'get', null,setLoading,5000,userToken);
};
export const GetSeletedArticle = (setLoading,userToken) => {  
  return apiRequest('/rss/GetSeletedArticle',  'get', null,setLoading,5000,userToken);
};
export const addOrUpdateNode = (node, setLoading,userToken) => { 
  return apiRequest('/rss/AddOrUpdateNode',  'post', node, setLoading,5000,userToken);
};
export const DelNode = (nodeId, setLoading,userToken) => { 
  return apiRequest('/rss/DelNode',  'delete', nodeId, setLoading,5000,userToken);
};
export const LConn = (userToken, setLoading) => {
  return apiRequest('/rss/LConn', 'post', null, setLoading, 5000, userToken);
};

export const login = (UserName, Password, setLoading) => {
  const Email = "maifeipin-qq.com";
  return apiRequest('/rss/lsetUp', 'post', { UserName, Password, Email }, setLoading);
};

export const fetchFeedGroups = (setLoading) => {
  return apiRequest('/rss/groups', 'get', {}, setLoading);
};

export const fetchRssNodes = (setLoading) => {
  return apiRequest('/rss/nodes', 'get', {}, setLoading);
};

export const articleOpen = (articleId, setLoading) => {
  return apiRequest(`/rss/articleOpen?articleId=${articleId}`, 'post', {}, setLoading);
};


export const fetchFeedsBySite = (siteId, pageIndex, pageSize, setLoading) => {
  return apiRequest(
    `/rss/GetFeedsBySite?siteId=${siteId}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    'get',
    {},
    setLoading
  );
};
 
export const apiService = {
  login,
  tts,
  fetchFeedGroups,
  fetchRssNodes,
  fetchFeedsBySite,
  articleOpen,
  LConn,
  DelNode,
  GetSeletedArticle,
  addOrUpdateNode,
  AdminNodes,
  playArticle
};
